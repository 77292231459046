.loader .MuiDialog-paper {
  box-shadow: none;
  background-color: transparent;
}
.loader .progress {
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}
